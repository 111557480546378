import React from "react";
import { Row, Container, Col, DropdownButton, Dropdown, Breadcrumb } from "react-bootstrap";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import SocialMedia from "../components/socialMedia";
import BlogPost from "../components/blogPost";
import { cookbook_sections } from "../components/cookbookData";
import DutchOvenCookingTipsLink from "../components/dutchOvenCookingTipsLink";
import PrevNextButtons from "../components/prevNextButtons";

const uri = "/cookbook/";

const CampingCookbookPage = ({ location, data: { images } }) => {
  const searchQuery = location.state?.searchQuery;

  var imgarr = [];
  for (var img of images.edges) {
    imgarr[img.node.name] = img.node.childImageSharp.gatsbyImageData;
  }

  const cookbook_sections_dropdown = cookbook_sections.filter((rec) => rec.name !== "Dutch Oven");

  const sideBarOptions = (
    <>
      <div id="cookbook-index-top">
        <Link to="/cookbook/">Camping Cookbook</Link>
      </div>
      {cookbook_sections.map((section) => (
        <div key={section.link} className="d-flex cookbook-index-links">
          <div className="generic-menu-block me-1">&nbsp;&nbsp;</div>
          <div>
            <Link className="me-1" to={"/cookbook/" + section.link + "/"}>
              {section.menuName}
            </Link>
          </div>
        </div>
      ))}
    </>
  );

  const dropDownOptions = (
    <DropdownButton title="Cookbook Sections">
      <Dropdown.Item key={0} href="/cookbook/">
        Top
      </Dropdown.Item>
      {cookbook_sections_dropdown.map((section) => (
        <Dropdown.Item key={section.link} href={"/cookbook/" + section.link + "/"}>
          {section.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );

  return (
    <Layout
      pageInfo={{ pageName: "cookbook", searchQuery: searchQuery }}
      sideBarOptions={sideBarOptions}
      dropDownOptions={dropDownOptions}
      sideBarWidth="190px"
    >
      <Seo
        title="Camping Cookbook"
        keywords={[`cookbook`, `recipes`]}
        description="The best online camping cookbook!"
        imageSrc="/campfire-896196_1280.jpg"
        imageWidth="1280"
        imageHeight="854"
        imageAlt="Cooking at Camp"
        url={uri}
      />

      <Container fluid className="text-center">
        <Row className="justify-content-left my-3" id="cookbook-top">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Cookbook</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <BlogPost
          title="Online Camping Cookbook"
          adCount={0}
          header={<PrevNextButtons nextLink="/cookbook/beverages" nextName="Next" />}
        >
          <Container fluid className="text-center">
            <Row className="justify-content-center flex">
              <Col className="p-4" id="cookbook-top-text">
                Welcome to the free online camping cookbook!
              </Col>
            </Row>

            <Row>
              <StaticImage src="../images/cookbook/campfire-896196_1280.jpg" alt="Campfire" />
            </Row>

            <Row className="d-flex justify-content-center flex">
              <div id="cookbook-campfire">
                <div className="d-flex justify-content-center pt-2">
                  <SocialMedia uri={"https://www.iloveitcamping.com" + uri} position="top" />
                </div>
              </div>
            </Row>

            <Row className="justify-content-center flex" id="top-section">
              <Col className="pt-2 ps-4 pe-4 pb-4" id="cookbook-top-text">
                {" "}
                These great recipes are designed to make when you are camping or just cooking outdoors, whether it’s
                with your friends, family, Scouting, or by yourself.
                <br />
                <br />
                These recipes were collected specifically for camping with some being super easy and some being more
                prep and time. There are some sections dedicated to Dutch oven cooking, if you are able to bring along a
                Dutch oven. If you are interested in Dutch oven cooking, see our
                <Link to="/dutch-oven-cooking-tips/" className="underline-link">
                  {" "}
                  Tips on Dutch Oven Cooking
                </Link>
                .
              </Col>
            </Row>

            <DutchOvenCookingTipsLink />
          </Container>
          <PrevNextButtons nextLink="/cookbook/beverages" nextName="Next" />
        </BlogPost>
      </Container>
    </Layout>
  );
};

export default CampingCookbookPage;

export const campingCookbookQuery = graphql`
  query campingCookbookImages {
    images: allFile(filter: { sourceInstanceName: { eq: "cookbook-images" } }) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
